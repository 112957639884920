import React from 'react';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import {
  getCurrentPositionUsingNativeGeolocator,
  getPracticeV3,
  getTimeByLocation,
} from '../../Client';
import { CLAIM_REGEX, getRandomImageFilename } from '../../Helpers';
import DateTodaySection from '../results/DateTodaySection';
import StickyGeolocationMessage from '../StickyGeolocationMessage';
import Insurance from './Insurance';
import ScheduleTable from './ScheduleTable';
import ServicesOffered from './ServicesOffered';
import SingleLocationMapSection from './SingleLocationMapSection';
import FooterBannerPen from '../FooterBannerPen';
import StickyBannerTelmed from '../StickyBannerTelmed';
import InternalDisplay from '../InternalDisplay';
import PromoA from '../PromoA';
import PromoB from '../PromoB';
import PromoC from '../PromoC';


class ListingPage extends React.Component {
  state = {
    practice: {
      practice_id: '',
      longitude: 121,
      latitude: 15,
      name: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      phone: '',
      email: '',
      about: '',
      services: '',
      website: '',
      xray: false,
      insurance: '',
      schedules: [],
      covid19TestServices: [],
      antibodyTestServices: [],
      partners: [],
    },
    userLocation: {
      lat: 15,
      lng: 121,
    },
    practiceUTCOffset: 0,
    userUTCOffset: 0,
    practiceDSTUTCOffset: 0,
    userDSTUTCOffset: 0,
    redirect: false,
    isGeolocationDenied: false,
    showInternalDisplay: false,
  };

  async componentDidMount() {
    let id = null;

    if (this.props.campaign) {
      console.log(this.props.campaign);
      if (this.props.campaign.error) {
        this.setState({
          redirect: true,
        });

        return;
      }

      this.setState(
        {
          practice: this.props.campaign.locations[0],
        },
        () => console.log(this.state.practice)
      );

      const userLocationResults = await getCurrentPositionUsingNativeGeolocator();
      if (userLocationResults.error) {
        if (userLocationResults.error.code === 1) {
          this.setState({
            isGeolocationDenied: true,
          });
        }
        return;
      }

      this.setState(
        {
          userLocation: {
            lat: userLocationResults.location.lat,
            lng: userLocationResults.location.lng,
          },
        },
        () => console.log(this.state)
      );

      // compute offsets
      await this.computePracticeOffsets();
      await this.computeUserOffsets();

      return;
    }

    id = this.props.match ? this.props.match.params.slug.split('-')[0] : null;

    if (this.props.preview) {
      if (!this.props.practice) {
        this.setState({
          redirect: true,
        });
        return;
      }

      this.setState(
        {
          practice: this.props.practice,
        },
        () => console.log(this.state.practice)
      );

      return;
    }

    // const getPracticeResults = await getPracticeV2(id);
    const getPracticeResults = await getPracticeV3(id);
    if (getPracticeResults.error) {
      this.setState({
        redirect: true,
      });

      return;
    }

    this.setState(
      {
        practice: getPracticeResults,
      },
      () => console.log(this.state.practice)
    );

    const userLocationResults = await getCurrentPositionUsingNativeGeolocator();
    if (userLocationResults.error) {
      if (userLocationResults.error.code === 1) {
        this.setState({
          isGeolocationDenied: true,
        });
      }
      return;
    }

    this.setState(
      {
        userLocation: {
          lat: userLocationResults.location.lat,
          lng: userLocationResults.location.lng,
        },
      },
      () => console.log(this.state)
    );

    // compute offsets
    await this.computePracticeOffsets();
    await this.computeUserOffsets();
  }

  computeUserOffsets = async () => {
    const today = Date.now() / 1000;
    const userUTCOffset = await getTimeByLocation(this.state.userLocation, today);

    if (userUTCOffset.error) {
      this.setState({
        redirect: true,
      });
      return;
    } else {
      this.setState(
        {
          userUTCOffset: userUTCOffset.rawOffset,
          userDSTUTCOffset: userUTCOffset.dstOffset,
        },
        () => console.log(this.state)
      );
    }
  };

  computePracticeOffsets = async () => {
    // UTC offset
    const today = Date.now() / 1000;
    console.log('This is the current practice.');
    console.log(this.state.practice);
    const practiceUTCOffset = await getTimeByLocation(
      { lat: this.state.practice.latitude, lng: this.state.practice.longitude },
      today
    );
    console.log(practiceUTCOffset);
    if (practiceUTCOffset.error) {
      this.setState({
        redirect: true,
      });
      return;
    } else {
      this.setState(
        {
          practiceUTCOffset: practiceUTCOffset.rawOffset,
          practiceDSTUTCOffset: practiceUTCOffset.dstOffset,
        },
        () => console.log(this.state)
      );
    }
  };

  shouldShowWeightLossBanner(state) {
    const exclusions = ['AL', 'DC', 'MS'];
    return !exclusions.includes(state) && !this.state.showInternalDisplay;
  }

  shouldShowTelemedFooter(state) {
    const exclusions = ['AL', 'CA', 'CT', 'NJ', 'NY', 'RI', 'SC'];
    return !exclusions.includes(state) && !this.state.showInternalDisplay;
  }

  shouldShowInternalDisplay() {
    return this.state.showInternalDisplay;
  }

  showInternalDisplay() {
    this.setState({
      showInternalDisplay: true,
    });
  }

  hideInternalDisplay() {
    this.setState({
      showInternalDisplay: false,
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/404" />;
    }
    const practiceInfo = this.state.practice;

    const address = `${practiceInfo.address}, ${practiceInfo.city}, ${practiceInfo.state} ${practiceInfo.zip}`;
    // const deltaUTCOffset = this.state.userUTCOffset - this.state.practiceUTCOffset;

    // head variables
    const title = `${practiceInfo.name} ${practiceInfo.city} ${practiceInfo.state} Urgent Care - UrgentCare.com`;
    const description = `Information for ${practiceInfo.name} ${practiceInfo.city} ${practiceInfo.state} Urgent Care - Hours, Location, Insurance Accepted, COVID-19 Testing Service Information`;
    const keywords = `${practiceInfo.name}, urgent care, ${practiceInfo.city}, ${practiceInfo.state}`;
    let website = '';
    if (practiceInfo.website !== '') {
      const website_pattern = /^https?:\/\//;
      website = website_pattern.test(practiceInfo.website)
        ? practiceInfo.website
        : 'https://' + practiceInfo.website;
    }

    return (
      <>
        {this.shouldShowInternalDisplay() && (
          <InternalDisplay
            dismissHandler={this.hideInternalDisplay.bind(this)}
            inPersonUrl={website}
          />
        )}
        {this.shouldShowWeightLossBanner(practiceInfo.state) && <StickyBannerTelmed />}
        <div className="section results">
          <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
          </Helmet>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="media">
                  <DateTodaySection />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="listings-box p-3 p-md-5 result-box qy-5 drop-shadow">
                  <div className="row">
                    <div className="col-12 col-md-7">
                      <div className="float-none float-md-left text-left d-block d-md-inline mr-3">
                        <img src={getRandomImageFilename()} className="img-fluid rounded" alt="" />
                      </div>
                      <ul className="list-unstyled" style={{ overflow: 'auto' }}>
                        <li>
                          <h5 className="mb-3">{this.state.practice.name}</h5>
                        </li>
                        <li className="mb-3">
                          <i className="fas fa-map-marker-alt mr-3"></i>
                          {address}
                        </li>

                        <li className="mb-3">
                          <i className="fas fa-phone-alt mr-3"></i>
                          {practiceInfo.phone}
                        </li>

                        <li className="mb-3 mail">
                          <i className="fas fa-globe-americas mr-3"></i>
                          <a target="_blank" rel="noopener noreferrer" href={website}>
                            Visit Website
                          </a>
                        </li>
                      </ul>

                      <hr className="mb-3" />
                      {this.props.location && CLAIM_REGEX.test(this.props.location.search) && (
                        <a
                          href={`https://admin.urgentcare.com/claim/${this.state.practice.practice_id}`}
                          className="btn-cta zy-5"
                        >
                          Claim this listing
                        </a>
                      )}

                      <ScheduleTable
                        schedules={this.state.practice.schedules}
                        userUTCOffset={this.state.userUTCOffset}
                        practiceUTCOffset={this.state.practiceUTCOffset}
                        DSTUTCOffset={this.state.practiceDSTUTCOffset}
                        userDSTUTCOffset={this.state.userDSTUTCOffset}
                      />
                    </div>
                    <div className="col-12 col-md-5">
                      <div className="map-responsive h-100 mb-5 rounded-10">
                        <SingleLocationMapSection
                          lat={practiceInfo.latitude}
                          lng={practiceInfo.longitude}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                  <div className="row mt-4">
                  <div className="col-md-6 mt-5 mb-0 mt-5 xy-md-5">
                    <div className="home-box round-border-10 h-100 p-2 p-md-5">
                      <img
                        src="/img/ico2/Appointment.svg"
                        alt="Book Your Doctor"
                        className=""
                        height="145px"
                        width="auto"
                        style={{ padding: '2px', margin: '0px auto', display: 'block' }}
                      />
                      <h3 className="mb-0 text-center">
                        Book Your Virtual Doctor's Appointment Today
                      </h3>
                      <p className="lead-violet mb-3 text-center">
                        Convenient, Affordable, Fast and Easy!
                      </p>
                      <h4 className="text-center pb-3 border-bottom">
                        UrgentCare.com Can Help With General & Acute Conditions
                      </h4>
                      <ul className="mb-5 half pl-4 md:pl-0">
                        <li>Medication Refill</li>
                        <li>Acid Reflux</li>
                        <li>Nausea</li>
                        <li>Vomiting</li>
                        <li>Acne</li>
                        <li>Pink Eye</li>
                        <li>Cold Sores</li>
                        <li>Shingles</li>
                      </ul>
                      <ul className="mb-5 half md:border-left pl-4">
                        <li>Allergies</li>
                        <li>Urinary Tract Infections</li>
                        <li>Sinus Headache</li>
                        <li>Sinus Infection</li>
                        <li>Adult Cold/Flu</li>
                        <li>Birth Control</li>
                        <li>And More</li>
                      </ul>
                      <div className="home-box-footer">
                        <button
                          onClick={this.showInternalDisplay.bind(this)}
                          className="learn-more btn green"
                        >
                          Chat With A Provider Online
                        </button>
                      </div>
                    </div>
                    {/* </a> */}
                  </div>
                    <div className="col-md-6 mt-5 mb-0 mt-5 xy-md-5">
                      <a
                        id="glp-internal"
                        href="/weight-loss-program"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="list-box round-border-10 h-100 p-2 p-md-5">
                          <img
                            src="/img/ico2/GLP-1b.svg"
                            alt="Lose weight with GLP-1"
                            className=""
                            height="145px"
                            width="auto"
                            style={{ padding: '2px', margin: '0px auto', display: 'block' }}
                          />
                          <h3 className="mb-0 mt-0 text-center">
                            Lose Weight With GLP&#x2011;1 Medications.
                          </h3>
                          <p className="lead-violet mb-3 text-center">Start Your Journey Today!</p>
                          <p className="mb-5">
                            Obesity is a disease that is often still associated with stigma, bias
                            and misinformation. We are working to help you make informed decisions
                            around obesity while making GLP-1 medications more accessible. Helping
                            fight obesity one person at a time.
                          </p>
                          <div className="home-box-footer">
                            <button className="learn-more btn green" href="/weight-loss-program">
                              Learn More
                            </button>
                          </div>
                        </div>
                      </a>
                    </div>

                  

                    {this.state.practice.services !== '' && (
                      <ServicesOffered
                        title="Services We Offer"
                        services={this.state.practice.services}
                      />
                    )}

                    {this.state.practice.insurance !== '' && (
                      <Insurance
                        title="Insurance Carriers Accepted"
                        insurances={this.state.practice.insurance}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            {this.state.isGeolocationDenied && (
              <StickyGeolocationMessage message="For better geolocation experience, turn on your location permissions for the site." />
            )}
          </div>
        </div>
        {this.shouldShowTelemedFooter(practiceInfo.state) && (
          <FooterBannerPen clickHandler={this.showInternalDisplay.bind(this)} />
        )}
      </>
    );
  }
}

export default ListingPage;
