import React from 'react';
import { Helmet } from "react-helmet";
import PromoA from '../../PromoA';
import PromoB from '../../PromoB';
import PromoC from '../../PromoC';
import PromoD from '../../PromoD';
const PinkEye = () => {
    return (
        <>
            <Helmet>
                <title>Pink Eye Symptoms | UrgentCare.com</title>
            </Helmet>
            <div className="hero-image-title pinkeye">
                <div className="title-display">
                    <h2>Pink Eye Symptoms</h2>
                    <h4>What you should know</h4>
                </div>
            </div>

            <div className="section pt-5">
            
                <div className="container">
                
                    <div className="row">
                        <div className="col-12">
                            <p>If you've ever woken up with red, itchy eyes or, worse, crusted eyelids, you know how uncomfortable pink eye can be. It can be particularly concerning if you're unsure of what's causing the symptoms. Pink eye, or conjunctivitis, is one of the most common eye infections in both children and adults.</p>
                            
                            <PromoD go="pinkeye" />
                            <h1 className="text-center">How do I get Pink Eye?</h1>
                            <p>Pink eye, or conjunctivitis, is an infection or inflammation of the transparent membrane (conjunctiva) that lines your eyelid and the whites of your eyes. This inflammation causes the small blood vessels in your eyes to become more visible, giving your eyes a red or pink appearance. Pink eye can be caused by:</p>
                        <ul className="list">
                        <li>Viruses</li>
                        <li>Bacteria</li>
                        <li>Chemicals or Irritants</li>
                        <li>Allegies</li>
                        </ul>
                            <p>While pink eye can be uncomfortable, it’s usually not serious and rarely impacts vision. However, certain types of pink eye are highly contagious. Getting a proper diagnosis and early treatment can help prevent the spread to others.</p>
                       
                       
                       <p className="text-center mt-5 pl-5 pr-5"><a href="https://urgentcare.carehub.wellsync.com/conditions/miscellaneous-health/pink-eye" className="btn orange">Get Care Instantly</a></p>
                            
                            <h3 className="mt-5">Pink Eye Symptoms</h3>
                            <p>Pink eye can effect one or both eyes, symptoms can include:</p>
                
                <div className="symptoms">
                    <div className="half">
                        <p><img className="icon" src="/img/symptoms/pinkeye.jpg"/>
                            Red or Pink color in the whites of the eyes</p>
                        <p><img className="icon" src="/img/symptoms/wateryeye.jpg"/>Watery, teary eyes</p>
                        <p><img className="icon" src="/img/symptoms/redeye-burn.jpg"/>Itchy or burning eyes</p>
                        <p><img className="icon" src="/img/symptoms/discarge-eye.jpg"/>Thick white, yellow, or green discharge.</p>
                    </div>
                    <div className="half">
                        
                        <p><img className="icon" src="/img/symptoms/blury-vission.jpg"/>Blurred vision or increased sensitivity to light</p>
                        <p><img className="icon" src="/img/symptoms/swollen-eyes.jpg"/>Swollen eyelids</p>
                        <p><img className="icon" src="/img/symptoms/pinkeye.jpg"/>A gritty or scratchy fealing</p>                    </div>
                </div>
                <PromoA go="pinkeye" />
                        </div>
                    </div>
                </div>
            </div>
              
            <div className="bubble-head">
                <div className="container">
                    <div className="row">
                        <div className="col-12">           
                        <h2>Is the pink eye you have contagious?</h2>
                        <p>Certain types of pink eye are highly contagious and can spread for up to two weeks while symptoms persist.</p>
                        </div>
                    </div>
                </div>
            </div>
              <div className="list-bubble">
                <div className="bubble">
                    <h5>Viruses</h5>
                    <ul className="ml-5">
                        <li>Often caused by the same viruses responsible for the common cold or respiratory infections.</li>
                        <li>Commonly spreads from the nose to the eyes or through exposure to an infected person sneezing or coughing nearby.</li>
                        <li>Most common and highly contagious.</li>
                    </ul>
                </div>
                <div className="bubble">
                    <h5>Bacteria</h5>
                    <ul className="ml-5">
                        <li>Often caused by bacteria from the skin or respiratory system, sometimes linked to ear infections or strep throat.</li>
                        <li>Commonly spreads by touching the eye with unwashed hands or contaminated objects like makeup or contact lenses.</li>
                        <li>Less common than viral infections but highly contagious.</li>
                    </ul>
                </div>
                <div className="bubble">
                    <h5>Chemicals or Irritants</h5>
                    <ul className="ml-5">
                        <li>Often caused by foreign objects or chemical splashes, such as chlorine, air pollution, or car fumes.</li>
                        <li>Can occur through swimming, exposure to chemicals, or accidental contact.</li>
                        <li>Not contagious.</li>
                    </ul>
                </div>
                <div className="bubble">
                    <h5>Allegies</h5>
                    <p className="text-center"><small>(Allergic Conjunctivitits)</small></p>
                   <ul className="ml-5">
                        <li>Allergic pink eye is not an infection.</li>
                        <li>Often triggered by allergens like pollen, cosmetics, shampoo, pet dander, or dust.</li>
                        <li>Histamines cause inflammation in the body and eyes, leading to intensely itchy, pink, or red eyes.</li>
                        <li>Not contagious (unless a secondary viral or bacterial infection occurs).</li>
                    </ul>
                </div>
                </div>
                <div className="section pt-5">
                
                <div className="container">
                
                    <div className="row">
                        <div className="col-12">
                <h2>How can you treat pink eye?</h2>
                <p>Your doctor can suggest various treatments depending on the type of pink eye or allergic conjunctivitis you have:</p>
                            
                <div className="big-bubble">
                <div className="bubble">
                    <h3>Viral</h3>
                    <p>May require antiviral eye drops, steroids, cold or warm compresses, or artificial tears. Antibiotic medications are ineffective for this type.</p>
                </div>
                <div className="bubble">
                    <h3>Bacterial</h3>
                    <p>Likely needs antibiotic eye drops, ointments, or pills.</p>
                </div>
                </div>
                <div className="big-bubble">
                <div className="bubble">
                    <h3>Chemical or Irritant</h3>
                    <p>Rinse the affected eye with water for at least five minutes. If the irritant is chlorine or bleach, seek medical attention immediately. You can schedule an appointment with an MDLIVE doctor in 15 minutes or less.</p>
                </div>
                <div className="bubble">
                    <h3>Allegies</h3>
                    <p>Allergic conjunctivitis may resolve on its own once the underlying allergy is treated with antihistamines or the allergen is removed. Alternatively, your doctor might prescribe lubricating, decongestant, or antihistamine eye drops to alleviate symptoms.</p>
                </div>
                </div>
                
                <h2 className="text-center mt-5">How can you get help without going to a doctor’s office?</h2>
                <p>If your pink eye is contagious, early treatment helps prevent spreading it to others. Urgentcare.com offers fast, 24/7/365 provider communication. If you’re having severe symptoms or need relief fast, talk to a board-certified provider who can send a prescription to the pharmacy of your choice. Your Urgentcare.com provider can also help determine if something else is causing your pink eye.</p>
                
                
                <p className="text-center mt-5 pl-5 pr-5"><a href="https://urgentcare.carehub.wellsync.com/conditions/miscellaneous-health/pink-eye" className="btn orange">Get Care Instantly</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PinkEye;