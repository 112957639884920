import React from 'react';
import URLgo from './URLgo'; 

const PromoA = ({ go }) => {
    const href = URLgo[go] || URLgo['default'];

    return(
        <a href={href} className="promo a">
            <p>In most cases, you can be diagnosed and prescribed medication without scheduling an appointment.</p>
            <p className="btn orange">Get Care Instantly <strong className="price">$35</strong></p>            
        </a>
    );
}

export default PromoA;