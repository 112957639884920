import React from 'react';
import URLgo from './URLgo';


const PromoC = ({ go }) => {
   const href = URLgo[go] || URLgo['default'];

    return (
        <a href={href} className="promo c">
            <img src="/img/phone-doc.png" alt="urgent-care" className="img-fluid phone-doc" />
            <p><strong>Need General Medical Treatment Quickly?</strong></p>
            <p className="small">Get care at home without an appointment in most cases.</p>
            <p className="btn green">Get Care Instantly <strong className="price">$35</strong></p>            
        </a>
    );
}

export default PromoC;