import React from 'react';
import { Helmet } from "react-helmet";
import PromoA from '../../PromoA';
import PromoB from '../../PromoB';
import PromoC from '../../PromoC';
import PromoD from '../../PromoD';
const Allergies = () => {
    return (
        <>
            <Helmet>
                <title>Allergies | UrgentCare.com</title>
            </Helmet>
            <div className="hero-image-title allergies">
                <div className="title-display">
                    <h2>What Are Allergy Symptoms?</h2>
                    <h4>What you should know</h4>
                </div>
            </div>

            <div className="section pt-5">
            
                <div className="container">
                
                    <div className="row">
                        <div className="col-12">
                            <h1 className="text-center">Common Allergy Symptoms</h1>
                            
                            <p>Spring ushers in blooming trees, warmer breezes, and plenty of pollen. For those with allergies—and that's a significant number of people, as it's one of the most common chronic illnesses in the U.S.—the symptoms like sneezing, a runny nose, and puffy eyes are clear indicators that pollen season has arrived.</p>
                            
                            
                            <PromoD go="allergies" />
                            
                            <div className="symptoms">
                                <div className="half">
                                    <p><img className="icon" src="/img/symptoms/wateryeye.jpg"/>
                                        Itchy, watery eyes</p>
                                    <p><img className="icon" src="/img/symptoms/sneezing.jpg"/> Sneezing</p>
                                    <p><img className="icon" src="/img/symptoms/runningnose.jpg"/>Runny or stuffy nose / congestion</p>
                           
                                </div>
                                <div className="half">
                                    <p><img className="icon" src="/img/symptoms/cough.jpg"/>Itchy, scratchy throat and cough</p>
                                    <p><img className="icon" src="/img/symptoms/rash.jpg"/>Rashes or Hives</p>
                                    <p><img className="icon" src="/img/symptoms/breathing-trouble.jpg"/>Wheezing</p>
                                </div>
                            </div>
                            
                            <p>Symptoms of seasonal allergies, often referred to as hay fever, typically intensify during spring, summer, and fall when pollen levels are elevated. In contrast, perennial allergies are persistent throughout the year and result from sensitivities to allergens such as dust mites, pet dander, insect particles, or mold. Both seasonal and perennial allergens can lead to allergic rhinitis</p>
                            
                            
                            <p>Since it's possible to develop allergies to almost anything and symptoms can often be mistaken for other conditions, a doctor can assess whether your symptoms are due to allergies or another issue. They can also help you manage those symptoms and suggest appropriate tests or treatments.</p>
                                     
                               <PromoA go="allergies" />
                            <h3>What allergy treatments are available? </h3>
                            <p>There are various allergy treatments available, and some may be more effective for you than others. Options include over-the-counter medications like antihistamines, nasal sprays, and decongestants, as well as prescription medications. A doctor can help determine the best treatment for you, all from the comfort of your home through Urgentcare.com.</p>
                            
                            <p>In addition to medications, these tips can help alleviate allergy symptoms:</p>

                            <ul>
                            <li>Shower at night to remove pollen.</li>
                            <li>Use saline nasal rinses and sprays to clear away pollen and other allergens.</li>
                            <li>Vitamin C can reduce nasal secretions and inflammation.</li>
                            <li>Stay hydrated by drinking plenty of water.</li>
                            <li>Exercise regularly to boost your immune system.</li>
                            <li>Avoid foods that may increase congestion, such as cow’s milk and gluten.</li>
                            <li>Spicy foods like cayenne, chili pepper, ginger, and herbal teas can help open nasal passages and reduce mucus production.</li>
                            <li>Consuming raw honey from your local area may help strengthen your immune system.</li>
                            </ul>
                            
                            <h3>How can you get help without going to a doctor’s office?</h3>
                            <p>Urgentcare.com offers fast, 24/7/365 provider communication. If you’re having severe symptoms or need relief fast, talk to a board-certified provider who can send a prescription to the pharmacy of your choice. Your Urgentcare.com provider can also help determine if something else is causing your concerns.</p>
                            
                            <p className="text-center mt-5 pl-5 pr-5"><a href="https://urgentcare.carehub.wellsync.com/conditions/miscellaneous-health/allergies" className="btn orange">Get Care Instantly</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Allergies;