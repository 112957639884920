import React from 'react';
import URLgo from './URLgo';

const PromoB = ({ go }) => {
    const href = URLgo[go] || URLgo['default'];

    return(
        <a href={href} className="promo b">
            <p><strong>Feeling unwell?</strong></p>
            <p>Complete a quick questionnaire, and our providers can diagnose and prescribe medication if needed.</p>
            <p className="btn orange">Get Care Instantly  <strong className="price">$35</strong></p>            
        </a>
    );
}

export default PromoB;