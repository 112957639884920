const URLgo = {
  sinus: "https://urgentcare.carehub.wellsync.com/conditions/miscellaneous-health/sinus-infection-sinus-headache",
  uti: "https://urgentcare.carehub.wellsync.com/conditions/womens-health/urinary-tract-infection",
  pinkeye: "https://urgentcare.carehub.wellsync.com/conditions/miscellaneous-health/pink-eye",
  skinrash: "https://urgentcare.carehub.wellsync.com/conditions/dermatology/skin-rashes-eczema",
  flu: "https://urgentcare.carehub.wellsync.com/conditions/miscellaneous-health/flu",
  covid: "https://urgentcare.carehub.wellsync.com/conditions/miscellaneous-health/covid-19",
  allergies: "https://urgentcare.carehub.wellsync.com/conditions/miscellaneous-health/allergies",
  // Add more condition-specific URLs here if needed
  default: "https://urgentcare.carehub.wellsync.com/conditions"
};

export default URLgo;