import React from 'react';
import URLgo from './URLgo';


const PromoD = ({ go }) => {
   const href = URLgo[go] || URLgo['default'];

    return (
        <a href={href} className="promo d">
         
            <div className="half-info">
            <div className="info-full">
              <h3><strong>Connected Care</strong> - Anytime, Anywhere</h3>
              <div className="info-break">
              <p>Recieve care from thousands of high-quality providers accepting patients today. Choose from the various conditions to get started.</p>
              <p className="text-center"><div className="btn orange"> Get Care Instantly $35/visit</div></p>
              </div>
    
            </div>     
            
            <div className="easy-steps">
            <h3 className="text-center">Get Started in 3 Easy Steps</h3> 
            <div className="steps"> 
              <div className="step one">
                <div className="icon"><span className="order">1</span><img
                  src="/img/ico2/Appointment.svg"
                  alt="Complete an Online Form"
                  className=""
                  height="110px"
                  width="auto"
                /></div> Complete an Online Form
              </div>
              <div className="step two">
                <div className="icon"><span className="order"> 2</span><img
                  src="/img/ico2/Medical_Care_Icon.svg"
                  alt="Medical Review"
                  className=""
                  height="110px"
                  width="auto"
                /></div> Medical Review
              </div>
              <div className="step three">
                <div className="icon"><span className="order">3</span><img
                  src="/img/ico2/Drug_Screening.svg"
                  alt="Prescription Submitted"
                  className="pt-3 pb-2"
                  height="110px"
                  width="auto"
                /></div> Prescription Submitted
              </div>
            </div>
            </div>
            </div>
        </a>
    );
}

export default PromoD;