import React from 'react';

class StickyBannerTelmed extends React.Component {
  render() {
    return (
      <div className="alert secondary" role="alert" id="alert-container">
        
        <span className="message">Prefer To Handle It From Home? <small>Connect with a health provider now for </small>$35</span>
        <div className="alert-action-btn">
          <a
            href="https://urgentcare.carehub.wellsync.com/conditions"
            target="_blank"
            rel="noopener noreferrer"
            className="btn green"
          >
            Get&nbsp;Care&nbsp;Instantly
          </a>
        </div>
      
      
      
      </div>
    );
  }
}

export default StickyBannerTelmed;
