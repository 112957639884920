import React from 'react';
import { Helmet } from "react-helmet";
import PromoA from '../../PromoA';
import PromoB from '../../PromoB';
import PromoC from '../../PromoC';
import PromoD from '../../PromoD';
const Covid19 = () => {
    return (
        <>
            <Helmet>
                <title>Covid-19 | UrgentCare.com</title>
            </Helmet>
            <div className="hero-image-title covid">
                <div className="title-display">
                    <h2>Covid-19</h2>
                    <h4>What you should know</h4>
                </div>
            </div>

            <div className="section pt-5">
            
                <div className="container">
                
                    <div className="row">
                        <div className="col-12">
                            <h1 className="text-center">COVID-19: Overview, Symptoms, and Prevention</h1>
                            
                            <h3 className="mt-5">What is COVID-19?</h3>
                            <p>COVID-19, caused by the SARS-CoV-2 virus, is a contagious respiratory illness that can range from mild to severe. Emerging in late 2019, it quickly became a global pandemic, significantly impacting health systems worldwide. COVID-19 primarily spreads through respiratory droplets when an infected person talks, coughs, or sneezes, and it can also be transmitted through contaminated surfaces.</p>
                       
                            <p>While many recover fully, some individuals experience severe illness, long-term complications, or death, particularly those with underlying health conditions, older adults, and the unvaccinated.</p>
                            
                            <PromoD go="covid" />
                            
                            <h3 className="mt-5">Symptoms of COVID-19</h3>
                            <p>COVID-19 symptoms vary widely, ranging from no symptoms (asymptomatic) to severe respiratory distress. Common symptoms include:</p>
                            <ul>
                                <li>Fever or chills</li>
                                <li>Cough, which may be persistent</li>
                                <li>Shortness of breath or difficulty breathing</li>
                                <li>Fatigue</li>
                                <li>Muscle or body aches</li>
                                <li>Headache</li>
                                <li>Sore throat</li>
                                <li>Loss of taste or smell (anosmia), often sudden</li>
                                <li>Nasal congestion or runny nose</li>
                                <li>Nausea, vomiting, or diarrhea</li>
                            </ul>
                            <p>Symptoms typically appear 2–14 days after exposure to the virus. Severe cases may lead to hospitalization due to complications like pneumonia or organ failure.</p>
                       
                            <h3 className="mt-5">Causes and Transmission</h3>
                            <p>COVID-19 spreads through respiratory droplets, but smaller particles, called aerosols, may also contribute to transmission, particularly in enclosed spaces. The virus can also spread by touching a contaminated surface and then touching the mouth, nose, or eyes.</p>
                            
                            <h4>Risk Factors for Infection:</h4>
                            <ul>
                                <li>Close contact with infected individuals</li>
                                <li>Crowded or poorly ventilated indoor spaces</li>
                                <li>Not wearing a mask in high-risk areas</li>
                                <li>Weakened immune system due to medical conditions or treatments</li>
                            </ul>
                         
                            <h4>Potential Complications</h4>
                            <p>COVID-19 can lead to complications, particularly in high-risk individuals. These may include:</p>
                            <ul className="mb-5">
                                <li>Pneumonia and severe respiratory distress</li>
                                <li>Blood clots and stroke</li>
                                <li>Heart problems like myocarditis</li>
                                <li>Kidney damage or failure</li>
                                <li>Long COVID: Prolonged symptoms, such as fatigue, brain fog, and shortness of breath, lasting weeks or months</li>
                            </ul>
                            
                            <p className="my-5"><PromoB go="covid"/></p>
                            
                           <h3 className="mt-5">Prevention</h3>
                           <p>Preventing COVID-19 involves a combination of vaccination, personal hygiene, and avoiding high-risk behaviors.</p>
                           
                           <h4>Key Prevention Tips:</h4>
                           <ul>
                                <li><strong>Vaccination:</strong> Get fully vaccinated, including booster shots as recommended. Vaccines are highly effective in preventing severe illness and death</li>
                                <li><strong>Wear Masks:</strong> Use well-fitted masks in crowded or indoor public spaces, especially in areas of high transmission</li>
                                <li><strong>Practice Social Distancing:</strong> Maintain a safe distance from others, particularly in crowded areas</li>
                                <li><strong>Wash Hands Frequently:</strong> Use soap and water for at least 20 seconds or use hand sanitizer with at least 60% alcohol</li>
                                <li><strong>Avoid Touching Your Face:</strong> This helps reduce the risk of transferring the virus from surfaces</li>
                           </ul>
                           
                           <h3>Diagnosis and Treatment</h3>
                           
                           <h4>Diagnosis:</h4>
                           
                           <p>COVID-19 is diagnosed using diagnostic tests, including:</p>
                           <ul>
                                <li><strong>PCR Tests:</strong> Detect viral genetic material</li>
                                <li><strong>Antigen Tests:</strong> Provide quicker results but may be less sensitive</li>
                           </ul>
                           
                           <h4>Treatment:</h4>
                           <ul>
                                <li><strong>Rest and Fluids:</strong> Essential for recovery in mild cases</li>
                                <li><strong>Over-the-Counter Medications:</strong> Manage fever, cough, or aches</li>
                                <li><strong>Antiviral Medications:</strong> For high-risk patients, medications like Paxlovid or Remdesivir may reduce the severity and duration of illness</li>
                                <li><strong>Hospital Care:</strong> Severe cases may require oxygen therapy, ventilation, or specialized care for organ support</li>
                           </ul>
                           
                           <h3>When to Seek Medical Attention</h3>
                           
                           <p>Seek emergency care if you experience:</p>
                           <ul>
                                <li>Difficulty breathing</li>
                                <li>Persistent chest pain or pressure</li>
                                <li>Confusion or inability to stay awake</li>
                                <li>Bluish lips or face</li>
                           </ul>
                           <p>These symptoms may indicate severe illness requiring immediate intervention.</p>
                           
                           <h3>Protecting Yourself and Others</h3>
                           
                           <p>COVID-19 is preventable with the right measures. Vaccination, combined with healthy behaviors like mask-wearing and handwashing, remains the most effective way to reduce your risk and protect those around you.</p>
                           <p className="my-5"><PromoC go="covid" /></p>
                           <h3>The Importance of Staying Informed</h3>
                           <p>Staying updated on COVID-19 developments is essential as new variants and guidance emerge. Following trusted health sources and practicing prevention strategies will help keep you and your community safe.</p>
                           
                           
                           
                           <h2 className="mt-5">How can you get help without going to a doctor’s office?</h2>
                           <p>Urgentcare.com offers fast, 24/7/365 provider communication. If you’re having severe symptoms or need relief fast, talk to a board-certified provider who can send a prescription to the pharmacy of your choice. Your Urgentcare.com provider can also help determine if something else is causing your concerns.</p>
                           
                           <p className="text-center mt-5 pl-5 pr-5"><a href="https://urgentcare.carehub.wellsync.com/conditions/miscellaneous-health/covid-19" className="btn orange">Get Care Instantly</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Covid19;