import React from 'react';
import { Helmet } from "react-helmet";
import FactSection from './FactSection.js';
import PromoD from '../PromoD';

const CoveredConditions = () => {
return (
        <>
            <Helmet>
                <title>What UrgentCare.com Can Help You With Today?</title>
            </Helmet>
            <div className="section  hero-doc ">
                   
                    <img src="/img/doc-computer-wide.jpeg" alt="UrgentCare" className="hero-doc-image"/>
                    <img src="img/ico2/Medical_Care_Icon.svg" height="96px" width="auto" className="hero-icon" alt='Urgent Care Medical Care'/>
                    <div className="hero-text">
                    <h1>What Can UrgentCare.com Help You With Today?</h1>
                    <p>Let UrgentCare.com Connect You To A Board-Certified Provider, Now.</p>
                    </div>
            </div>
     
            <div className="container doc-outline">
            <div className="banner-title">
              

                <h2 className="purple-box">UrgentCare.com Covered Conditions</h2>    

            </div>
            <br className="clear-both"/>
                <div className="container ">
                    <div className="row ">
                      <div className="col-12 text-center mt-3 mb-5">
                          <h2 className="purple-text">On-Demand Acute Care and General Health</h2>
                           <h5>Board-Certified Provider • On-Demand 24/7 • Save Time with Async Communication</h5>
                         </div>

                          <PromoD />
                <div className="col-xs-12 col-md-4 conditions-list">
                   <h4 className="purple-text">General Health</h4>                   
                    <ul>
                        <li>Acid Reflux</li>
                        <li>Cold Sores</li>
                        <li><a className="underline" href="/covered-conditions/covid-19">Covid 19</a></li>
                        <li>Headache</li>
                        <li><a className="underline" href="/covered-conditions/pink-eye">Pink Eye</a></li>
                        <li>Smoking Cessation</li>
                        <li><a className="underline" href="/covered-conditions/allergies">Allergies</a></li>
                        <li>Excessive Sweating</li>
                        <li>Nausea/Vomiting</li>
                        <li><a className="underline" href="/covered-conditions/sinus-infection-symptoms">Sinus Headaches/Sinus Infection</a></li>
                        <li><a className="underline" href="/covered-conditions/flu">Flu - Adult</a></li>
                    </ul>

                    <h4>Men's Health</h4>
                    <ul>
                        <li>Erectile Dysfunction</li>
                        <li>Men's Hair Loss</li>
                        <li>Excessive Sweating</li>
                        <li>Premature Ejaculation</li>
                        <li>Acne</li>
                        <li>Genital Herpes</li>
                    </ul>
                </div>
                
                <div className="col-xs-12 col-md-4 conditions-list">
                <h4>Women's Health</h4>
                <ul>
                    <li>Acne</li>
                    <li>Bacterial Vaginosis/Yeast Infections</li>
                    <li>Eyelash Growth</li>
                    <li>Women's Hair Loss</li>
                    <li>Anti-Aging</li>
                    <li>Birth Control</li>
                    <li><a className="underline" href="/covered-conditions/uti">Urinary Tract Infections (UTI)</a></li>
                    <li>Genital Herpes</li>
                </ul>
                <h4>Sexual Health</h4>
                <ul>
                    <li>Birth Control</li>
                    <li>Bacterial Vaginosis/Yeast Infections</li>
                    <li>Premature Ejaculation</li>
                    <li><a className="underline" href="/covered-conditions/uti">Urinary Tract Infections
                    (UTI)</a></li>
                    <li>STI Exposure Treatment
                    (Doxy PEP)</li>
                    <li>Erectile Dysfunction</li>
                    <li>Genital Herpes</li>
                </ul>
                
  
                </div>
                <div className="col-xs-12 col-md-4 conditions-list">
                   <h4>Medication Refill</h4>                   
                    <ul>
                        <li>Short-Term Medication Refill</li>
                        <li>High Cholesterol Refill</li>
                        <li>High Blood Pressure Refill</li>
                        <li>Diabetic Medication Refill</li>
                    </ul>
                
                    <h4>Skin &amp; Hair</h4>
                    <ul>
                        <li>Acne</li>
                        <li>Dandruff</li>
                        <li>Men's Hair Loss</li>
                        <li><a className="underline" href="/covered-conditions/skin-rash">Skin Rashes</a></li>
                        <li>Excessive Sweating</li>
                        <li>Scabies</li>
                        <li>Anti-Aging</li>
                        <li>Eyelash Growth</li>
                        <li>Rosacea</li>
                        <li>Women's Hair Loss</li>
                        <li>Shingles</li>
                        <li>Poison Ivy</li>
                    </ul>
                </div>
                
            </div>
        </div>
   
     
           
    </div>


     <div className="container mb-10">
   <div className="row p-4">
   <h4 className="text-center">How to get medical help without leaving your home?</h4>
   <p>If you're needing to speak with a provider and prefer to stay at home, we have you covered. Urgentcare.com offers fast, 24/7/365 provider communication. If you're having severe symptoms or need fast relief, talk to a certified provider who can send a prescription to the pharmacy of your choice. Your Urgentcare.com provider can also help determine if something else is going on.</p>

   <div className="text-center display-block">
    <a href="https://urgentcare.carehub.wellsync.com/conditions" className="btn green " target='_blank' rel="noopener noreferrer">Get Care Instantly</a>
  </div>
            
  </div>
   <FactSection />
</div>

        </>
    )
}

                
export default CoveredConditions;