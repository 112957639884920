import React from 'react';
import { Helmet } from 'react-helmet';

// Separate component for steps
const BMISteps = () => (
  <>
    <h4>How to Use a BMI Calculator</h4>
    <ul>
      <li>Enter your height.</li>
      <li>Enter your weight.</li>
      <li>Click "Calculate Your BMI."</li>
    </ul>
    <p>The calculator will give you a number that helps you understand your health.</p>
    <p className="text-center">
      <a href="#bmi" className="btn">
        Calculate your BMI
      </a>
    </p>
  </>
);

// Main BMI Calculator component
const BMIcalculator = () => {
  return (
    <>
      <Helmet>
        <title>Calculate Your BMI | Understand Your Health | UrgentCare.com</title>
        <meta
          name="description"
          content="Discover your BMI with our easy-to-use calculator. Learn what your BMI means for your health and explore weight loss options with UrgentCare.com. Start today!"
        />
        <meta property="og:title" content="Calculate Your BMI | UrgentCare.com" />
        <meta
          property="og:description"
          content="Use our BMI calculator to assess your health, understand your BMI, and get started on your weight loss journey with UrgentCare.com's programs."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://urgentcare.com/bmi-calculator" />
      </Helmet>
      <div className="bmi-hero" id="bmi">
        <iframe
          title="BMI Calculator"
          className="calculator"
          src="https://www.t2d.com/widget/bmi"
        ></iframe>
        

        
        <a
          href="https://urgentcare.com/weight-loss-program?utm_source=urgentcare&utm_medium=ppc&utm_campaign=A2170022057"
          className="one"
        >
          <h5>
            Weight loss has never been
            <br /> more affordable.
          </h5>
          <div className="med">
            <p>
              ONLY $10.00 <br />A DAY
            </p>
            <img src="img/meds/glp1-tirzvial.png" alt="Medical Weight Loss Program" />
          </div>
          <div className="cta-btn">Get Started</div>
        </a>

        <a
          href="https://urgentcare.com/weight-loss-program?utm_source=urgentcare&utm_medium=ppc&utm_campaign=A2170022057"
          className="two"
        >
          <h5>
            Join Millions
            <br /> losing weight with GLP-1's.
          </h5>
          <div className="med">
            <p>
              ONLY $7.00 <br />A DAY
            </p>
            <img src="img/meds/glp1-semavial.png" alt="Medical Weight Loss Program" />
          </div>
          <div className="cta-btn">Get Started</div>
        </a>
        
   
      </div>

      <div className="section bmi-content">
        <div className="container">
          <h2 className="text-center">What is BMI?</h2>
          <img className="float-right" src="img/bmi/healthyfoods.png" alt="Healthy foods for BMI" />
          <p>
            BMI, or Body Mass Index, is a simple way to check if your weight is healthy for your
            height. It uses a formula to compare your weight and height, but you don’t have to worry
            about the math—a BMI calculator can do it for you!
          </p>

          <BMISteps />
          <img className="float-left full" src="img/bmi/graph.png" alt="BMI Graph" />
          <h4>What Do BMI Numbers Mean?</h4>

          <ul>
            <li>
              <strong>Under 18.5</strong>: Underweight – You may need to gain weight for better
              health.
            </li>
            <li>
              <strong>18.5 to 24.9</strong>: Healthy weight – Great job!
            </li>
            <li>
              <strong>25 to 29.9</strong>: Overweight – It might be time to make some healthy
              changes.
            </li>
            <li>
              <strong>30 and above</strong>: Obese – You might need extra help, like a medical
              weight loss program.
            </li>
          </ul>

          <h4>Why Does BMI Matter?</h4>
          <img className="float-right" src="img/bmi/stages.png" alt="Importance of BMI" />
          <p>BMI helps doctors and specialists understand if you might have health risks, like:</p>
          <ul>
            <li>Heart disease</li>
            <li>Type 2 diabetes</li>
            <li>High blood pressure</li>
            <li>Certain types of cancer</li>
          </ul>
          <br className="clear-both" />

          <h4>What BMI Doesn’t Show</h4>
          <img
            className="float-right"
            src="img/bmi/bmimag.png"
            alt="Weight Loss at UrgentCare.com"
          />
          <p>BMI is useful, but it doesn’t tell the full story. For example:</p>
          <ul>
            <li>Someone with a lot of muscle might have a high BMI but still be healthy.</li>
            <li>BMI doesn’t show where fat is stored, which is important for health.</li>
            <li>It also doesn’t account for age, gender, or other health factors.</li>
          </ul>

          <h4>When to Talk to a Doctor</h4>
          <p>
            If your BMI is under 18.5 or over 25, it’s a good idea to talk to a doctor. They can
            help with other tools, like checking body fat, fitness levels, or diet, to get a better
            picture of your health.
          </p>
          <p className="text-center">
            <a
              href="https://urgentcare.com/weight-loss-program?utm_source=urgentcare&utm_medium=ppc&utm_campaign=A2170022057"
              className="btn"
            >
              Learn More
            </a>
          </p>
          <img
            className="float-left"
            src="img/bmi/seeyourdoctor.png"
            alt="Consult with your doctor"
          />
          <h4>Why Choose UrgentCare.com for Medical Weight Loss?</h4>

          <p>
            If your BMI shows you might need to lose weight, UrgentCare.com can help with programs that include medications like Semaglutide and Tirzepatide.
          </p>
          <ul>
          <li>
            <strong>Safe Medications</strong>: These control hunger and help manage weight.
          </li>
            <li>
              <strong>Easy Process</strong>: Sign up online, complete a short questionnaire, and get
              medication shipped to your door.
            </li>
            <li>
              <strong>Affordable Pricing</strong>: Transparent costs with no surprises.
            </li>
          </ul>
          <br className="clear-both" />
          <div className="weightloss-steps bg-white">
            <div className="list-steps">
              <div className="step">
                <img
                  className="hero"
                  src="img/steps-clipboard-icon.png"
                  alt="Complete Intake Questionnaire"
                />
                <h4>
                  <img src="img/steps-one.png" className="step-img" alt="Step One" />
                  Complete Intake Questionnaire
                </h4>
                <p>Your provider will use this information to prepare for your consultation.</p>
              </div>
              <div className="step bordered">
                <img
                  className="hero"
                  src="img/steps-drconv-icon.png"
                  alt="Consult with Your Provider"
                />
                <h4>
                  <img src="img/steps-two.png" className="step-img" alt="Step Two" />
                  Consult with Your Provider
                </h4>
                <p>
                  Your licensed weight loss provider will check to determine what treatment is right
                  for you.
                </p>
              </div>
              <div className="step">
                <img
                  className="hero"
                  src="img/steps-shiptime-icon.png"
                  alt="Enjoy Convenient Delivery"
                />
                <h4>
                  <img src="img/steps-three.png" className="step-img" alt="Step Three" />
                  Enjoy Convenient Delivery
                </h4>
                <p>
                  Your prescription will arrive at your doorstep every month in discrete packaging.
                </p>
              </div>
            </div>
          </div>

          <h4>Start Your Health Journey Today!</h4>
          <p>
            Your BMI is just the beginning. Take the first step toward a healthier you with
            UrgentCare.com.
          </p>
          <p className="text-center">
            <strong>Our team is ready to help you succeed!</strong>
          </p>

          <div className="text-center">
            <a
              href="https://urgentcare.com/weight-loss-program?utm_source=urgentcare&utm_medium=ppc&utm_campaign=A2170022057"
              className="get-started"
            >
              <div className="info">
                <h4>
                  Get
                  <br /> Started
                  <br className="hide" /> Today
                </h4>
                <div className="outline">
                  <strong>Semaglutide $209/mo</strong>
                  <br /> or
                  <br /> <strong>Tirzepatide $209/mo</strong>
                </div>
                <div className="btn">Learn More</div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default BMIcalculator;
